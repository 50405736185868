<template>
  <el-dialog
      title="编辑意愿"
      width="35%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 20%" ref="form">
      <el-row>
        <el-col :span="15">
          <el-form-item label="收款进度" prop="progressName">
            <el-input v-model="form.progressName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="15">
          <el-form-item label="颜色选择">
            <el-color-picker v-model="form.progressColor"></el-color-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updateCollectionProgress()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "collection-edit",
  data() {
    return {
      form: {
        //公司名
        id:this.collection.id,
        progressName: this.collection.progressName,
        progressColor: this.collection.progressColor,
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      rules: {
        progressName: [
          {required: true, message: '请输入收款进度参数名称', trigger: 'change'},
        ],
      },
      dialogState: this.state,
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    collection:{
      type: Object,
      required: true,
      default(){return{}},
    }
  },
  created() {
  },
  methods: {
    //修改收款进度参数
    updateCollectionProgress: function () {
      this.$axios({
        method: "POST",
        url: "/collectionProgress/updateCollection",
        params: this.form
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success("修改成功!")
          let self = this;
          setTimeout(function () {
            self.close()
          },1500)
        } else {
          this.$message.error(response.data.msg)
        }
      });
    },

    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>

</style>