<template>
  <div>
    <div class="block" style="margin: 25px 50px 200px 50px">
      <h3 style="display: inline-block">阈值调节</h3>
      <el-popover
          ref="popover"
          placement="right-start"
          title="收款进度参数--阈值调节"
          width="700"
          trigger="hover">
        <div v-html="tips"></div>
      </el-popover>
      <i v-popover:popover class="el-icon-question" style="font-size: 20px; color: #017ffd; margin-left: 10px" />
      <div class="block">
        <el-slider
            v-model="table.value"
            show-input @change="changeN(table.value)">
        </el-slider>
      </div>
    </div>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-table
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :data="table.collectionList"
          border
          max-height="700"
          :default-sort="{prop: 'id', order: 'ascending'}"
          style="width: 99%">
        <el-table-column prop="progressName" label="收款进度参数名称" align="center"></el-table-column>
        <el-table-column prop="progressValue" label="收款进度范围" align="center"></el-table-column>
<!--        <el-table-column prop="progressColor" label="颜色" align="center"><template slot-scope="scope">-->
<!--          <div style="height: 100%;width: 100%;display: flex;justify-content: center;align-items: center;">-->
<!--            <div :style="{backgroundColor:scope.row.progressColor,width:'100px',height:'30px'}"></div>-->
<!--          </div>-->
<!--        </template></el-table-column>-->
<!--        <el-table-column prop="isValid" label="是否激活" align="center">-->
<!--          <template slot-scope="scope">-->
<!--            <el-switch-->
<!--                @change="isValidChange(scope.row)"-->
<!--                v-model="scope.row.isValid"-->
<!--                :active-value="1"-->
<!--                :inactive-value="0"-->
<!--                active-color="#13ce66"-->
<!--                inactive-color="#ff4949">-->
<!--            </el-switch>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column width="350" align="center" fixed="right" label="操作">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button-group>-->
<!--              <el-button type="warning" size="mini" @click="openEdit(scope.row)">编辑</el-button>-->
<!--            </el-button-group>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <collectionEdit v-if="dialog.collectionEditState" :collection="dialog.collection" :state="dialog.collectionEditState" @close="close" />
  </div>
</template>

<script>
import collectionEdit from "@/pages/parameter/collection/collection-edit";
export default {
  name: "collection-list",
  data() {
    return {
      isValid:"",
      viewName:"",
      tips:"参数描述：（阈值、X->小定、N->未收满订单、W->已收满）\n" +
          "\n" + "<br>" +
          "X->小定：范围为 0% 到 阈值，售前状态，默认添加售前预约\n" +
          "\n" + "<br>" +
          "N->未收满订单：范围为 阈值 到 100%， 售后状态， 默认添加售后预约\n" +
          "\n" + "<br>" +
          "W->已收满：范围为 >= 100%, 状态与添加预约与 N->未收满订单 一致\n" +
          "\n" + "<br>" +
          "\n" + "<br>" +
          "功能描述一：为各公司用户设定默认的收款阈值，只有当收款进度达到收款阈值的订单才算有效订单，状态为已订单，收款未达到阈值则为小定，状态为小定；\n" +
          "\n" + "<br>" +
          "功能描述二：小定状态为售前状态，该订单所对应的客资可添加售前预约；已订单状态为售后状态，该订单所对应的客资默认添加售后预约；\n" +
          "\n" + "<br>" +
          "功能描述三：当对收款进度的阈值进行调节时，该公司所有未收满的订单会根据更新后的阈值与收款比例更新所有订单状态；（例：原来收款进度为小定，在阈值调低后，收款比例高于阈值，则订单收款进度变为已订单，对应的客资与预约状态更新为已订单，可添加售后预约）\n" +
          "\n",
      tenantCrop: localStorage.getItem("tenantCrop"),
      table: {
        collectionList: [],
        value:0,
        page: 1,
        limit: 100,
        total: 0,
      },
      dialog: {
        collectionEditState: false,
        collection: {},
      }
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    collectionEdit,
  },
  created() {
    this.queryList();
  },
  methods: {
    search() {
      this.table.page = 1;
      this.queryList();
    },
    //查寻收款进度参数列表
    queryList: function () {
      this.$axios({
        method: "GET",
        url: "/collectionProgress/collectionProgressList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          tenantCrop: this.tenantCrop,
        }
      }).then(response => {
        if (response.data.data.list!=null) {
          this.table.value = response.data.data.list[0].keyValue;
        } else {
          this.table.value = 0
        }
        this.table.total = response.data.data.total;
        this.table.collectionList = response.data.data.list;
      })
    },
    isValidChange: function (value) {
      this.$axios({
        method: "POST",
        url: '/collectionProgress/updateCollection',
        params: {
          "id": value.id,
          "isValid": value.isValid,
          "tenantCrop": this.tenantCrop
        }
      }).then(response => {
        let isValidState = response.data.code === 200;
        this.$message({
          message: isValidState ? "修改激活状态成功" : response.data.msg,
          type: isValidState ? "success" : 'error'
        });
      })
    },
    //根据id删除
    deleteById: function (value) {
      this.$axios({
        method: "POST",
        url: "/collectionProgress/deleteCollection",
        params: {
          "id": value.id,
          "tenantCrop": this.tenantCrop
        }
      }).then(response => {
        let isValidState = response.data.code === 200;
        this.$message({
          message: isValidState ? "修改激活状态成功" : response.data.msg,
          type: isValidState ? "success" : 'error'
        });
      })
    },
    //修改阈值
    changeN(data) {
      this.$axios({
        method: "POST",
        url: "/collectionProgress/updateThreshold",
        params: {
          "tenantCrop": this.tenantCrop,
          "keyValue" : data,
        }
      }).then(response => {
        let isValidState = response.data.code === 200;
        this.$message({
          message: isValidState ? "阈值修改成功" : response.data.msg,
          type: isValidState ? "success" : 'error'
        });
      });
      //刷新页面
      location.reload();
    },
    //打开编辑页面
    openEdit(data) {
      console.log(data)
      this.dialog.collectionEditState = true
      this.dialog.collection = data
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryList()
    },
    close: function (state) {
      this.dialog.collectionEditState = state;
      this.queryList();
    }
  }
}
</script>

<style scoped>

</style>